import { User } from './../../interfaces/user';

export interface UserState {
  user?: User;
  isLoading: boolean;
  token?: string;
}

export const userInitialState: UserState = {
  isLoading: false,
};
