import { Proxy } from '../../interfaces/proxy';
import { IdMap } from '../../interfaces/utils';

export interface ProxyState {
  proxiesById: IdMap<Proxy>;
  isLoading: boolean;
}

export const proxyInitialState: ProxyState = {
  proxiesById: {},
  isLoading: false,
};
