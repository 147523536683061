import styled from '@emotion/styled';
import dimensions from '../../utils/dimensions';

type CellProps = {
  rowName?: string;
};

export const OrderTable = styled.div`
  border-collapse: collapse;
  min-width: 100%;
  margin-bottom: 2em;
`;

export const TemplateGrid = styled.div`
  display: grid;
  grid-template-columns:
    minmax(200px, 1.5fr) /* Name */
    minmax(50px, 0.2fr) /* Qty */
    minmax(150px, 0.5fr) /* Price */
    minmax(150px, 0.5fr) /* Status */
    minmax(150px, 1fr) /* Shop & Proxy */
    minmax(100px, 0.5fr) /* Release Date */
    minmax(100px, 0.5fr); /* Options */
`;

export const OrderHeader = styled(TemplateGrid)`
  background-color: #f9fafc;
  text-transform: uppercase;
  border-radius: 4px;
  font-size: 1em;
  border: none;

  @media (max-width: 1000px) {
    display: none;
  }
`;

export const Row = styled(TemplateGrid)`
  grid-template-areas: 'name qty price status shop release options';
  border-bottom: 1px solid #dedede;

  @media (max-width: ${dimensions.maxwidthTablet}px) {
    /* grid-template-columns: repeat(auto-fit, minmax(100px, 1fr)); */
    grid-template-columns:
      minmax(100px, 1fr) /* Image */
      minmax(170px, 1fr) /* Shop & Proxy */
      minmax(20px, 0.2fr) /* Qty */
      minmax(100px, 1fr) /* Price */
      minmax(100px, 1fr) /* Release Date */
      minmax(70px, 0.5fr); /* Options */
    grid-template-rows: repeat(2, minmax(50px, 1fr));
    grid-template-areas:
      'name specialName specialName specialName status status'
      'name shop qty price release options';
  }

  @media (max-width: ${dimensions.maxwidthMobile}px) {
    /* grid-template-columns: repeat(8, 1fr); */
    grid-template-columns:
      minmax(80px, 1fr) /* Image */
      minmax(20px, 0.2fr) /* Qty */
      minmax(50px, 1fr) /* Shop & Proxy */
      minmax(50px, 1fr) /* Price */
      minmax(60px, 1fr) /* Release Date */
      minmax(70px, 0.5fr); /* Options */
    grid-template-rows: repeat(3, minmax(50px, 1fr));
    grid-template-areas:
      'name specialName specialName specialName specialName specialName'
      'name shop shop shop status status '
      'name qty price price release options';
  }
`;

export const Cell = styled.div<CellProps>`
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 0.5em;

  grid-area: ${(props) => props.rowName};

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @media (max-width: ${dimensions.maxwidthTablet}px) {
    padding: 0 0.25em;
    margin: 0;
  }

  @media (max-width: ${dimensions.maxwidthMobile}px) {
    padding: 0 0.25em;
    margin: 0;
  }
`;

export const SelectWrapper = styled.div`
  flex: 1;
`;

export const SelectCell = styled(Cell)``;

export const SpecialName = styled(Cell)<CellProps>`
  display: none;

  @media (max-width: ${dimensions.maxwidthTablet}px) {
    display: flex;
    align-items: center;
    justify-content: left;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  @media (max-width: ${dimensions.maxwidthMobile}px) {
    display: flex;
    align-items: center;
    justify-content: left;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const NameCell = styled(Cell)<CellProps>`
  @media (max-width: ${dimensions.maxwidthTablet}px) {
    padding: 0;
    align-items: normal;
  }
`;

export const DoubleCell = styled(Cell)<CellProps>`
  flex-direction: column;
  justify-content: center;

  span {
    margin: 0;
    width: 100%;
  }

  span:nth-of-type(2) {
    color: grey;
  }
`;

export const HeaderCell = styled(Cell)`
  background-color: #f9fafc;
  text-transform: uppercase;
  border-radius: 4px;
  font-size: 0.75em;
  border: none;
`;

export const Link = styled.a`
  display: contents;
  text-decoration: none;
  color: inherit;
`;

export const ItemName = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  @media (max-width: 1000px) {
    display: none;
  }
`;

export const ImageStyle = styled.img`
  max-width: 3em;
  margin-right: 15px;
  border-radius: 50%;

  @media (max-width: ${dimensions.maxwidthTablet}px) {
    border-radius: 0%;
    max-width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
  }
`;

export const SpecialImage = styled(ImageStyle)`
  --background: #efefef;
  background-image: linear-gradient(
    90deg,
    var(--background) 0px,
    white 40px,
    var(--background) 80px
  );

  @keyframes shine {
    from {
      background-position: 200%;
    }
    to {
      background-position: -40px;
    }
  }

  background-size: 500px;
  animation: shine 1s infinite linear;

  @media (min-width: ${dimensions.maxwidthTablet}px) {
    height: 3em;
  }
`;

export const OptionButton = styled.button`
  padding: 0.25em;
  text-decoration: none;
  border: 0;
  cursor: pointer;
  background-color: white;
`;
