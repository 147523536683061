// import { ShopState } from './shop.initialState';
// import { PayloadAction } from '@reduxjs/toolkit';

export const shopReducer = {
  // fetch: (state: OrderState, action: PayloadAction<string>): OrderState => {
  //   return {
  //     ...state,
  //     isLoading: false,
  //   };
  // },
};
