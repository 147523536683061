import React, { useLayoutEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { login } from '../../store/user/user.slice';
import { Props } from './Auth.interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import qs from 'qs';
import { isUserLoggedInSelector } from '../../selectors/user';

const Auth: React.FC<Props> = ({ children }) => {
  const isLoading = useSelector((state: RootState) => state.user.isLoading);
  const isUserLoggedIn = useSelector(isUserLoggedInSelector);
  const [isError, setIsError] = useState(false);
  const dispatch = useDispatch();
  const { search } = useLocation();

  useLayoutEffect(() => {
    // Accept example: http://localhost:3001/auth/twitter/callback?oauth_token=XXXXXXXXX&oauth_verifier=YYYYYYYYY
    // Denial example: http://localhost:3000/auth/twitter/callback?denied=XXXXXXXXX
    if (!search || !qs.parse(search.substr(1)).oauth_token) {
      setIsError(true);
      return;
    }
    dispatch(login(search));
  }, []);

  if (isUserLoggedIn) {
    return <Redirect to='/home' />;
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error, try again.</div>;
  }

  return <>{children}</>;
};

export default Auth;
