import { RootState } from './../../store/store';
import { connect } from 'react-redux';
import Layout from './Layout';
import { isUserLoggedInSelector } from '../../selectors/user';

const mapStateToProps = (state: RootState) => ({
  isUserLoggedIn: isUserLoggedInSelector(state),
  isLoading: state.user.isLoading,
});

export default connect(mapStateToProps)(Layout);
