import { Currency } from './currency';
import { Proxy } from './proxy';
import { Shop } from './shop';

export enum Status {
  wishlist = 'wishlist',
  preorder = 'preorder',
  order = 'order',
}

export interface Order {
  _id: string;
  owner: string;
  item?: string; // for cached items
  itemName?: string;
  shop: Shop;
  proxy?: Proxy;
  price: number;
  currency: Currency;
  quantity: number;
  status: Status;
  preorderStartDate?: Date;
  preorderEndDate?: Date;
  releaseDate: Date;
  itemUrl: string;
}

export interface FormOrder {
  item?: string; // for cached items
  itemName?: string;
  shop: string;
  proxy?: string | null;
  price: number;
  currency: string;
  quantity: number;
  status: Status;
  preorderStartDate?: Date;
  preorderEndDate?: Date;
  releaseDate: Date;
  itemUrl: string;
}

export type UpdateFormOrder = FormOrder & { _id: string };
