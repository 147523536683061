import React from 'react';
import { LoginButton, SeparationBar, TextName } from './Login.styles';
import { FaTwitter } from 'react-icons/fa';
const Login: React.FC = () => {
  return (
    <div>
      <LoginButton href={`${process.env.REACT_APP_TWITTER_URL}`}>
        <FaTwitter style={{ marginRight: '10px' }} />
        <SeparationBar />
        <TextName>Log in with Twitter</TextName>
      </LoginButton>
    </div>
  );
};

export default Login;
