import { connect } from 'react-redux';
import Header from './Header';
import { logoutUserActionCreator } from '../../store/user';
import { AppDispatch } from '../../store/store';

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  logout: () => dispatch(logoutUserActionCreator()),
});

export default connect(null, mapDispatchToProps)(Header);
