import { IdMap } from './../interfaces/utils';
import axios from 'axios';
import { RequestMethods, RequestParams } from '../interfaces/utils';

export const apiRequest = async (
  endpoint: string,
  method: RequestMethods = 'GET',
  params?: RequestParams
  // eslint-disable-next-line
): Promise<any> => {
  // console.log({ endpoint, method, params });
  const url = `${process.env.REACT_APP_API_URL}${endpoint}`;
  const headers: IdMap<string> = params?.headers || {};
  const queryParameters = params?.query;
  const data = params?.body;
  const token = params?.token;
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  const { data: responseData } = await axios({
    method,
    url,
    headers,
    params: queryParameters,
    data,
  });

  return responseData;
};
