import { FormOrder, UpdateFormOrder } from './../interfaces/order';
import { Order } from '../interfaces/order';
import { PaginatedResponse, PaginationOptions } from '../interfaces/utils';
import { apiRequest } from '../utils/api';

export const fetchOrders = async (
  pagination: PaginationOptions,
  token: string
): Promise<PaginatedResponse<{ orders: Order[] }>> => {
  const data = await apiRequest('orders/', 'GET', { query: pagination, token });
  return { orders: data.orders, total: data.total };
};

export const createOrder = async (order: FormOrder, token: string): Promise<Order> => {
  const data = await apiRequest('orders/', 'POST', { token, body: order });
  return data.order;
};

export const updateOrder = async (
  order: Order | UpdateFormOrder,
  token: string
): Promise<Order> => {
  const data = await apiRequest(`orders/${order._id}`, 'PUT', { token, body: order });
  return data.order;
};

export const deleteOrder = async (orderId: string, token: string): Promise<Order> => {
  const data = await apiRequest(`orders/${orderId}`, 'DELETE', { token });
  return data.order;
};
