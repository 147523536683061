import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { isUserLoggedInSelector } from '../../../selectors/user';
import { logoutUserActionCreator } from '../../../store/user';

import {
  MobileNavContainer,
  MenuButton,
  XMenuButton,
  MainContainer,
  LinksContainer,
} from './MobileHeader.styles';

const MobileHeader: React.FC = () => {
  const [isToggledOn, setToggle] = useState(false);
  const toggle = () => setToggle(!isToggledOn);
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const isUserLoggedIn = useSelector(isUserLoggedInSelector);

  const logOut = () => {
    dispatch(logoutUserActionCreator());
  };

  const homeRoute = !isUserLoggedIn ? '/' : '/home';

  useEffect(() => {
    setToggle(false);
  }, [pathname, isUserLoggedIn]);

  return !isUserLoggedIn ? null : (
    <MobileNavContainer>
      <MenuButton onClick={toggle} aria-label={`${isToggledOn ? 'close menu' : 'open menu'}`}>
        <XMenuButton isToggledOn={isToggledOn} />
      </MenuButton>
      {isToggledOn && (
        <MainContainer>
          <LinksContainer>
            <NavLink to={homeRoute} activeClassName='current-page' aria-label='Go to Main Page'>
              Home
            </NavLink>
            <NavLink to='/orders' activeClassName='current-page' aria-label='Go to Order Page'>
              Orders
            </NavLink>
            <NavLink to='/about' activeClassName='current-page' aria-label='Go to About Page'>
              About
            </NavLink>
            {!isUserLoggedIn ? null : <a onClick={logOut}>Log Out</a>}
          </LinksContainer>
        </MainContainer>
      )}
    </MobileNavContainer>
  );
};

export default MobileHeader;
