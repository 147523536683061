import React from 'react';
import Select from 'react-select';
import { FaRegTrashAlt, FaRegEdit } from 'react-icons/fa';
import {
  Cell,
  SelectWrapper,
  DoubleCell,
  Row,
  Link,
  NameCell,
  ItemName,
  SpecialName,
  OptionButton,
  SpecialImage,
} from '../Orders.style';

const LoadingRow: React.FC = () => {
  const count = 2;
  return (
    <>
      {Array.from({ length: count }, (_, index) => (
        <Row key={`loader-${index}`}>
          <NameCell rowName='name'>
            <Link>
              <SpecialImage
                src='data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAAAUAAAAECAQAAADsOj3LAAAADklEQVR42mNkgANGQkwAAJoABWH6GPAAAAAASUVORK5CYII='
                alt='Loading'
              />
              <ItemName>Loading</ItemName>
            </Link>
          </NameCell>
          <SpecialName rowName='specialName'>
            <span>Loading</span>
          </SpecialName>
          <Cell rowName='qty'>...</Cell>
          <DoubleCell rowName='price'>
            <span>Loading</span>
            <span>Loading</span>
          </DoubleCell>
          <Cell rowName='status'>
            <SelectWrapper>
              <Select />
            </SelectWrapper>
          </Cell>
          <DoubleCell rowName='shop'>
            <span>Loading</span>
            <span>Loading</span>
          </DoubleCell>
          <Cell rowName='release'>Loading</Cell>
          <Cell rowName='options'>
            <OptionButton>
              <FaRegTrashAlt style={{ stroke: 'black', strokeWidth: '2' }} size={20} />
            </OptionButton>
            <OptionButton>
              <FaRegEdit style={{ stroke: 'purple', strokeWidth: '2' }} size={20} />
            </OptionButton>
          </Cell>
        </Row>
      ))}
    </>
  );
};

export default LoadingRow;
