import React, { useLayoutEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Layout from '../Layout';
import Auth from '../Auth';
import Orders from '../Orders';
import NoMatch from './NoMatch';
import PrivateRoute from '../PrivateRoute/PrivateRoute';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { fetchUser } from '../../store/user/user.slice';
import LoginPage from '../LoginPage';
import Home from '../Home';
import User from '../User';

const App: React.FC = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const token = useSelector((state: RootState) => state.user.token);
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    if (token && !user) {
      dispatch(fetchUser());
    }
  }, [user, token]);

  return (
    <BrowserRouter>
      <Layout>
        <Switch>
          <Route exact path='/'>
            <LoginPage />
          </Route>
          <Route path='/auth/twitter/callback'>
            <Auth />
          </Route>
          <PrivateRoute exact path='/home'>
            <Home />
          </PrivateRoute>
          <PrivateRoute exact path='/orders'>
            <Orders />
          </PrivateRoute>
          <PrivateRoute exact path='/settings'>
            <User />
          </PrivateRoute>
          <Route path='*'>
            <NoMatch />
          </Route>
        </Switch>
      </Layout>
    </BrowserRouter>
  );
};

export default App;
