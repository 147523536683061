import { UserState, userInitialState } from './user.initialState';
import { saveTokenToLocalStorage } from '../../utils/storage';

// These are reducers but they also create the actions.
export const userReducer = {
  logout: (): UserState => {
    saveTokenToLocalStorage('');
    return userInitialState;
  },
};
