import styled from '@emotion/styled';
import React from 'react';
import { GlobalStyles } from '../../GlobalStyles';
import dimensions from '../../utils/dimensions';
import Header from '../Header';
import { Props } from './Layout.interfaces';

const MainContent = styled.main`
  margin: 0 auto;
  max-width: ${dimensions.maxwidthDesktop}px;
  padding-left: ${dimensions.paddingHorizontalDesktop}em;
  padding-right: ${dimensions.paddingHorizontalDesktop}em;

  @media (max-width: ${dimensions.maxwidthTablet}px) {
    padding-left: ${dimensions.paddingHorizontalTablet}em;
    padding-right: ${dimensions.paddingHorizontalTablet}em;
  }
  @media (max-width: ${dimensions.maxwidthMobile}px) {
    padding-left: ${dimensions.paddingHorizontalMobile}em;
    padding-right: ${dimensions.paddingHorizontalMobile}em;
  }
`;

const Layout: React.FC<Props> = ({ children }) => {
  return (
    <div>
      <GlobalStyles />
      <Header />
      <MainContent>{children}</MainContent>
    </div>
  );
};

export default Layout;
