import styled from '@emotion/styled';
import dimensions from '../../../utils/dimensions';

type FieldProps = {
  fieldName?: string;
};

export const Form = styled.form`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 0.75em;

  grid-template-areas:
    'name name name name shop shop proxy proxy'
    'itemUrl itemUrl itemUrl itemUrl price quantity currency status'
    'submit . . . . . . .';

  margin-bottom: 1em;

  @media (max-width: ${dimensions.maxwidthTablet}px) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-gap: 0.5em;

    grid-template-areas:
      'name name shop proxy'
      'itemUrl itemUrl price quantity'
      'currency status submit submit';
  }

  @media (max-width: ${dimensions.maxwidthMobile}px) {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-gap: 0.25em;

    grid-template-areas:
      'name name name name name name'
      'itemUrl itemUrl itemUrl itemUrl itemUrl itemUrl'
      'shop shop shop proxy proxy proxy'
      'price price quantity quantity currency currency'
      'status status status submit submit submit';
  }
`;

export const TextLabel = styled.label`
  position: absolute;
  font-size: 0.75em;
  top: -0.5em;
  left: 6px;
  background-color: white;
  padding: 0 4px;

  &:focus {
    border-color: #2684ff;
    box-shadow: 0 0 0 1px #2684ff;
    outline: none;
  }
`;

export const TextField = styled.input`
  padding: 2px 8px;
  height: 38px;
  width: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  border-color: hsl(0, 0%, 80%);

  &:focus {
    border-color: #2684ff;
    box-shadow: 0 0 0 1px #2684ff;
    outline: none;
  }
`;

export const SubmitButton = styled.button`
  padding: 2px 8px;
  height: 38px;
  width: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  border-color: hsl(0, 0%, 80%);

  @media (max-width: ${dimensions.maxwidthTablet}px) {
  }

  @media (max-width: ${dimensions.maxwidthMobile}px) {
    padding-top: 0.2em;
  }
`;

export const Field = styled.div<FieldProps>`
  margin-top: 0.4em;
  position: relative;
  grid-area: ${(props) => props.fieldName};
`;
