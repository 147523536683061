import { ThunkApiParams } from './../store';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { User } from '../../interfaces/user';
import { getTokenFromLocalStorage, saveTokenToLocalStorage } from '../../utils/storage';
import parseToken from './parseToken';
import { userInitialState, UserState } from './user.initialState';
import { userReducer } from './user.reducer';
import * as userService from './../../services/user';

const token = getTokenFromLocalStorage();

const newState: UserState = {
  ...userInitialState,
  token,
};

export const fetchUser = createAsyncThunk<User, string | undefined, ThunkApiParams>(
  'user/fetchUser',
  async (token, thunkAPI) => {
    const response = await userService.fetchUser(token || thunkAPI.getState().user.token || '');
    return response;
  }
);

export const updateUser = createAsyncThunk<User, User, ThunkApiParams>(
  'user/updateUser',
  async (user, thunkAPI) => {
    const response = await userService.updateUser(user, thunkAPI.getState().user.token || '');
    return response;
  }
);

export const login = createAsyncThunk<{ user: User; token: string }, string, ThunkApiParams>(
  'user/login',
  async (queryString: string, thunkAPI) => {
    const response = await axios.get(
      `${process.env.REACT_APP_TWITTER_CALLBACK_URL}${queryString}`,
      {
        withCredentials: true,
      }
    );
    const { token } = response.data;
    const user = parseToken(token)!;
    thunkAPI.dispatch(fetchUser(token));

    // TODO check if user is defined
    saveTokenToLocalStorage(token);
    return { user, token };
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState: newState,
  reducers: userReducer,
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        // Add user to the state array
        state.isLoading = false;
        const { user, token } = action.payload;
        state.user = user;
        state.token = token;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.user = action.payload;
      });
  },
});

export const {
  actions: { logout: logoutUserActionCreator },
} = userSlice;

export default userSlice.reducer;
