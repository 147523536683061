import { arrayToIdMapAndOrdering } from './../../utils/utils';
import { ThunkApiParams } from './../store';
import { Currency } from '../../interfaces/currency';
import { currencyReducer } from './currency.reducer';
import * as currencyService from './../../services/currency';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { currencyInitialState } from './currency.initialState';

export const fetchCurrencies = createAsyncThunk<Currency[], undefined, ThunkApiParams>(
  'currency/fetchCurrencies',
  async (params, thunkAPI) => {
    const response = await currencyService.fetchCurrencies(thunkAPI.getState().user.token || '');
    return response;
  }
);

export const currencySlice = createSlice({
  name: 'currency',
  initialState: currencyInitialState,
  reducers: currencyReducer,
  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrencies.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCurrencies.fulfilled, (state, action) => {
        // Add user to the state array
        state.isLoading = false;
        const { idMap } = arrayToIdMapAndOrdering(action.payload as Currency[], '_id');
        state.currenciesById = idMap;
      });
  },
});

export default currencySlice.reducer;

// export const {
//   actions: { create: createProxyActionCreator },
// } = currencySlice;
