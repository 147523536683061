import { useState } from 'react';

type FormType<T> = {
  formFields: T;
  createChangeHandler: (
    key: keyof T
  ) => (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  setFormField: (key: keyof T, value?: string) => void;
};

export function useFormFields<T>(initialValues: T): FormType<T> {
  const [formFields, setFormFields] = useState<T>(initialValues);
  const createChangeHandler = (key: keyof T) => (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const value = e.target.value;
    setFormFields((prev: T) => ({ ...prev, [key]: value }));
  };

  const setFormField = (key: keyof T, value?: string) =>
    setFormFields((prev: T) => ({ ...prev, [key]: value }));

  return { formFields, setFormField, createChangeHandler };
}
