import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { isUserLoggedInSelector } from '../../selectors/user';
import Login from '../Login';
import { LoginContainer } from './LoginPage.styles';

const LoginPage: React.FC = () => {
  const isLogged = useSelector(isUserLoggedInSelector);

  if (isLogged) {
    return <Redirect to='/home' />;
  }

  return (
    <LoginContainer>
      <div>
        <h1>Welcome to Fantastic Sloth</h1>
        <h2>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugiat atque deleniti fuga
          reprehenderit odit sunt libero
        </h2>
        <h2>Lorem ipsum dolor sit amet consectetur adipisicing elit.</h2>
        <h3>Choose how you want to Log in</h3>
      </div>
      <Login />
    </LoginContainer>
  );
};

export default LoginPage;
