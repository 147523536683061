import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { currenciesSelector } from '../../selectors/currency';
import { RootState } from '../../store/store';
import { updateUser } from '../../store/user/user.slice';
import { arrayToSelectOptions } from '../../utils/utils';

const User: React.FC = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const currencies = useSelector(currenciesSelector);
  const [selectedCurrency, setSelectedCurrency] = useState<string>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (user?.settings?.baseCurrency) {
      setSelectedCurrency(user.settings.baseCurrency);
    }
  }, [user]);

  const onCurrencySelect = useCallback(
    (obj: { value: string; label?: string } | null) => {
      const currencyId = obj!.value;
      if (!user || currencyId === selectedCurrency) {
        return;
      }
      setSelectedCurrency(currencyId);
      dispatch(
        updateUser({
          ...user,
          settings: {
            ...user.settings,
            baseCurrency: currencyId,
          },
        })
      );
    },
    [user]
  );

  const currencyOptions = useMemo(() => arrayToSelectOptions(currencies, 'shortName'), [
    currencies,
  ]);

  if (!user) {
    return null;
  }

  const options = currencies.map((currency) => {
    return { value: currency._id, label: currency.shortName };
  });

  const currencyValue = selectedCurrency
    ? {
        value: selectedCurrency,
        label: options.find((o) => o.value === selectedCurrency)?.label,
      }
    : undefined;

  return (
    <div>
      <span>Hi {user.name}!</span>
      <span>
        Your default currency is
        <Select
          options={currencyOptions}
          aria-label='Default currency value'
          value={currencyValue}
          onChange={onCurrencySelect}
        />
      </span>
    </div>
  );
};

export default User;
