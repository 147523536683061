import { IdMap } from '../interfaces/utils';

type IdMapAndOrdering<T> = { idMap: IdMap<T>; ordering: (keyof T)[] };
type KeyInput<T, K> = K | ((obj: T) => string);

/**
 * This function compose multiple fields into a key
 * @param array : normal array
 * @param key : key that you want to key by, or a function that generates the key.
 */
export const arrayToIdMapAndOrdering = <T, K extends keyof T>(
  array: T[],
  key: KeyInput<T, K>
): IdMapAndOrdering<T> => {
  const idMap: IdMap<T> = array.reduce((obj: IdMap<T>, element) => {
    const ekey = typeof key === 'function' ? key(element) : element[key];
    const id: string | number =
      typeof ekey === 'string' || typeof ekey === 'number' ? ekey : String(ekey);
    obj[id] = element;
    return obj;
  }, {});

  const ordering = array.map(
    (element) => (typeof key === 'function' ? key(element) : element[key]) as keyof T
  );

  return {
    idMap,
    ordering,
  };
};

export const arrayToSelectOptions = <T extends { _id: string }>(
  entities: T[],
  labelKey: keyof T,
  valueKey: keyof T = '_id'
): { value: string; label: string }[] =>
  entities.map((entity) => {
    // TODO string transformation
    return { value: String(entity[valueKey]), label: String(entity[labelKey]) };
  });

type SelectOption =
  | {
      value: string;
      label: string | undefined;
    }
  | undefined;

export const getSelectOption = <T extends { _id: string }>(
  a: string,
  b: T[],
  c: keyof T,
  d: keyof T = '_id'
): SelectOption =>
  a
    ? {
        value: a,
        // TODO string transformation
        label: b.find((value) => String(value[d]) === a)?.[c] as string | undefined,
      }
    : undefined;
