import { useCallback } from 'react';

type selectCallbackType = (
  obj: {
    value: string;
    label?: string;
  } | null
) => void;

export function useSelectCallback<T>(
  setFormField: (key: keyof T, value?: string) => void,
  key: keyof T
): selectCallbackType {
  return useCallback(
    (obj: { value: string; label?: string } | null) => {
      setFormField(key, obj ? obj.value : undefined);
    },
    [setFormField, key]
  );
}
