import React, { useCallback, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HeaderCell, OrderHeader, OrderTable } from './Orders.style';
import { userBaseCurrencySelector } from '../../selectors/currency';
import { ordersSelector } from '../../selectors/order';
import { fetchCurrencies } from '../../store/currency/currency.slice';
import { createOrder, fetchOrders } from '../../store/order/order.slice';
import { fetchProxies } from '../../store/proxy';
import { fetchShops } from '../../store/shop';
import { RootState } from '../../store/store';
import Pagination from '../Pagination';
import OrderForm from './OrderForm';
import OrderRow from './OrderRow';
import LoadingRow from './OrderRow/LoadingRow';
import { FormOrder } from '../../interfaces/order';

const defaultPageSize = 2;

const Orders: React.FC = () => {
  const isLoading = useSelector((state: RootState) => state.order.isLoading);
  const orders = useSelector(ordersSelector);
  const baseCurrency = useSelector(userBaseCurrencySelector);
  const ordersTotal = useSelector((state: RootState) => state.order.ordersTotal);

  const dispatch = useDispatch();

  const onFormSubmit = useCallback((order: FormOrder) => dispatch(createOrder(order)), []);

  useLayoutEffect(() => {
    dispatch(fetchOrders({ page: 0, pageSize: defaultPageSize }));
    dispatch(fetchShops());
    dispatch(fetchProxies());
    dispatch(fetchCurrencies());
  }, []);

  const TableHeader = () => (
    <OrderHeader>
      <HeaderCell>Name</HeaderCell>
      <HeaderCell>Qty</HeaderCell>
      <HeaderCell>Price</HeaderCell>
      <HeaderCell>Status</HeaderCell>
      <HeaderCell>Shop & Proxy</HeaderCell>
      <HeaderCell>Release Date</HeaderCell>
      <HeaderCell>Options</HeaderCell>
    </OrderHeader>
  );

  const tableData = orders.map((order) => (
    <OrderRow key={order._id} order={order} baseCurrency={baseCurrency} />
  ));

  return (
    <>
      <OrderForm onSubmit={onFormSubmit} />
      <OrderTable>
        <Pagination
          itemCount={ordersTotal}
          defaultPageSize={defaultPageSize}
          onPageChange={(pageNumber, pageSize) => {
            dispatch(fetchOrders({ page: pageNumber - 1, pageSize }));
          }}
        />
        <TableHeader />
        {isLoading ? <LoadingRow /> : tableData}
      </OrderTable>
    </>
  );
};

export default Orders;
