import { arrayToIdMapAndOrdering } from './../../utils/utils';
import { ThunkApiParams } from './../store';
import { Proxy } from '../../interfaces/proxy';
import { proxyReducer } from './proxy.reducer';
import * as proxyService from './../../services/proxy';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { proxyInitialState } from './proxy.initialState';

export const fetchProxies = createAsyncThunk<Proxy[], undefined, ThunkApiParams>(
  'proxy/fetchProxies',
  async (params, thunkAPI) => {
    const response = await proxyService.fetchProxies(thunkAPI.getState().user.token || '');
    return response;
  }
);

export const proxySlice = createSlice({
  name: 'proxy',
  initialState: proxyInitialState,
  reducers: proxyReducer,
  extraReducers: (builder) => {
    builder
      .addCase(fetchProxies.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchProxies.fulfilled, (state, action) => {
        // Add user to the state array
        state.isLoading = false;
        const { idMap } = arrayToIdMapAndOrdering(action.payload as Proxy[], '_id');
        state.proxiesById = idMap;
      });
  },
});

export default proxySlice.reducer;

// export const {
//   actions: { create: createProxyActionCreator },
// } = proxySlice;
