import React from 'react';
import styled from '@emotion/styled';

const Row = styled.div`
  display: flex;
`;

const Col = styled.div`
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
`;

const Stat = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  font-size: 8px;
  margin-top: 25px;
  padding: 10px 10px 0;
  text-transform: uppercase;
`;

const Value = styled.div`
  font-size: 28px;
  padding: 0 10px;
`;
const Card = styled.div`
  border-radius: 8px;
  color: white;
  padding: 10px;
  position: relative;
  background-color: #2298f1;
`;

const Title = styled.div`
  display: inline-block;
  font-size: 8px;
  padding: 10px 10px 0;
  text-transform: uppercase;
`;

const Home: React.FC = () => {
  return (
    <div>
      <div>
        <h2>Dashboard</h2>
      </div>
      <Row>
        <Col>
          <Card>
            <Title>all projects</Title>
            <i className='zmdi zmdi-upload'></i>
            <Value>89</Value>
            <Stat>
              <b>13</b>% increase
            </Stat>
          </Card>
        </Col>
        <Col>
          <Card>
            <Title>team members</Title>
            <i className='zmdi zmdi-upload'></i>
            <Value>5,990</Value>
            <Stat>
              <b>4</b>% increase
            </Stat>
          </Card>
        </Col>
        <Col>
          <Card>
            <Title>total budget</Title>
            <i className='zmdi zmdi-download'></i>
            <Value>$80,990</Value>
            <Stat>
              <b>13</b>% decrease
            </Stat>
          </Card>
        </Col>
        <Col>
          <Card>
            <Title>new customers</Title>
            <i className='zmdi zmdi-download'></i>
            <Value>3</Value>
            <Stat>
              <b>13</b>% decrease
            </Stat>
          </Card>
        </Col>
      </Row>
      <div>
        <h2>Sumary</h2>
      </div>
      <div></div>
    </div>
  );
};

export default Home;
