import { Shop } from '../../interfaces/shop';
import { IdMap } from '../../interfaces/utils';

export interface ShopState {
  shopsById: IdMap<Shop>;
  isLoading: boolean;
}

export const shopInitialState: ShopState = {
  shopsById: {},
  isLoading: false,
};
