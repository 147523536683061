import { User } from '../interfaces/user';
import { apiRequest } from '../utils/api';

export const fetchUser = async (token: string): Promise<User> => {
  const data = await apiRequest('user/', 'GET', { token });
  return data.user;
};

export const updateUser = async (user: User, token: string): Promise<User> => {
  const data = await apiRequest('user/', 'PUT', { token, body: user });
  return data.user;
};
