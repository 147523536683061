import { arrayToIdMapAndOrdering } from './../../utils/utils';
import { ThunkApiParams } from './../store';
import { Shop } from '../../interfaces/shop';
import { shopReducer } from './shop.reducer';
import * as shopService from './../../services/shop';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { shopInitialState } from './shop.initialState';

export const fetchShops = createAsyncThunk<Shop[], undefined, ThunkApiParams>(
  'shop/fetchShops',
  async (params, thunkAPI) => {
    const response = await shopService.fetchShops(thunkAPI.getState().user.token || '');
    return response;
  }
);

export const shopSlice = createSlice({
  name: 'shop',
  initialState: shopInitialState,
  reducers: shopReducer,
  extraReducers: (builder) => {
    builder
      .addCase(fetchShops.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchShops.fulfilled, (state, action) => {
        // Add user to the state array
        state.isLoading = false;
        const { idMap } = arrayToIdMapAndOrdering(action.payload as Shop[], '_id');
        state.shopsById = idMap;
      });
  },
});

export default shopSlice.reducer;

// export const {
//   actions: { create: createShopActionCreator },
// } = shopSlice;
