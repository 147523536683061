import React, { useCallback, useState } from 'react';
import { Props } from './interfaces';
import Select from 'react-select';
import Modal from '../../Modal';
import { useDispatch } from 'react-redux';
import OrderEdit from '../OrderEdit';
import { FaRegTrashAlt, FaRegEdit } from 'react-icons/fa';
import { Status, Status as StatusEnum } from '../../../interfaces/order';
import { convertCurrency, getCurrencySymbol } from '../../../utils/currency';
import { deleteOrder, updateOrder } from '../../../store/order/order.slice';
import {
  Cell,
  SelectWrapper,
  DoubleCell,
  ImageStyle,
  Row,
  Link,
  NameCell,
  ItemName,
  SpecialName,
  OptionButton,
} from '../Orders.style';
import { ModalContainer } from '../OrderEdit/OrderEdit.styles';

const statusColorMap: { [k in StatusEnum]: string } = {
  wishlist: '#4cd06d',
  preorder: 'red',
  order: 'orange',
};

const OrderRow: React.FC<Props> = ({ order, baseCurrency }) => {
  const {
    _id,
    itemName,
    itemUrl,
    status,
    quantity,
    price,
    proxy,
    currency,
    shop,
    releaseDate,
  } = order;

  const [orderStatus, setOrderStatus] = useState(status);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const dispatch = useDispatch();

  const onStatusSelect = useCallback(
    (
      obj: {
        value: string;
        label?: string | undefined;
      } | null
    ) => {
      const newStatus = obj?.value as Status;
      if (!newStatus) {
        return;
      }
      setOrderStatus(newStatus);
      dispatch(
        updateOrder({
          ...order,
          status: newStatus,
        })
      );
    },
    [order]
  );

  const statusOptions = Object.keys(Status).map((status) => ({
    value: status,
    label: Status[status as keyof typeof Status] as string,
  }));

  const statusValue = {
    value: orderStatus as string,
    label: Status[orderStatus] as string,
  };

  const finalDate = `${new Date(releaseDate).toLocaleString('en-US', {
    month: 'short',
  })} ${new Date(releaseDate).getFullYear()}`;

  return (
    <>
      <Row>
        <NameCell rowName='name'>
          <Link href={itemUrl} target='_new'>
            <ImageStyle
              src='https://img.amiami.com/images/product/thumb300/211/FIGURE-123004.jpg'
              alt={`Photo of ${itemName}`}
            />
            <ItemName>{itemName}</ItemName>
          </Link>
        </NameCell>
        <SpecialName rowName='specialName'>
          <span>{itemName}</span>
        </SpecialName>
        <Cell rowName='qty'>{quantity}</Cell>
        <DoubleCell rowName='price'>
          <span>
            {price} {currency.shortName}
          </span>
          <span>
            {baseCurrency
              ? `(${convertCurrency(price, currency.rate, baseCurrency.rate)} ${getCurrencySymbol(
                  baseCurrency.shortName
                )})`
              : ''}
          </span>
        </DoubleCell>

        <Cell rowName='status'>
          <SelectWrapper>
            <Select
              id='status'
              options={statusOptions}
              value={statusValue}
              onChange={onStatusSelect}
              styles={{
                singleValue: (prev) => ({
                  ...prev,
                  color: statusColorMap[statusValue.value as StatusEnum],
                }),
              }}
            />
          </SelectWrapper>
        </Cell>
        <DoubleCell rowName='shop'>
          <span>{shop?.name}</span>
          <span>{proxy ? `via ${proxy.name}` : ' '}</span>
        </DoubleCell>
        <Cell rowName='release'>{finalDate}</Cell>
        <Cell rowName='options'>
          <OptionButton
            onClick={() => {
              setShowDeleteModal(true);
            }}
          >
            <FaRegTrashAlt style={{ stroke: 'black', strokeWidth: '2' }} size={20} />
          </OptionButton>
          <OptionButton
            onClick={() => {
              setShowEditModal(true);
            }}
          >
            <FaRegEdit style={{ stroke: 'purple', strokeWidth: '2' }} size={20} />
          </OptionButton>
        </Cell>
      </Row>
      {showEditModal && (
        <Modal
          visible={showEditModal}
          dimBackground
          onClickedOutside={() => setShowEditModal(false)}
        >
          <OrderEdit order={order} close={() => setShowEditModal(false)} />
        </Modal>
      )}

      {showDeleteModal && (
        <Modal
          visible={showDeleteModal}
          dimBackground
          onClickedOutside={() => setShowDeleteModal(false)}
        >
          <ModalContainer>
            <h1>Are you sure</h1>
            <button onClick={() => dispatch(deleteOrder(_id))}>YES</button>
            <button onClick={() => setShowDeleteModal(false)}>NO</button>
          </ModalContainer>
        </Modal>
      )}
    </>
  );
};

export default OrderRow;
