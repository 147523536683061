import styled from '@emotion/styled';

export const LoginContainer = styled.div`
  min-height: 100vh;
  max-width: 100%;
  margin: 0 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;
