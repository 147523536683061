import React, { useState } from 'react';
import { Props } from './Pagination.interfaces';
import { PaginationContainer } from './Pagination.styles';

const Pagination: React.FC<Props> = ({ itemCount, onPageChange, defaultPageSize }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize || 20);

  const pageCount = Math.ceil(itemCount / pageSize);
  const prevPage = currentPage - 1;
  const nextPage = currentPage + 1;
  const hasNextPage = nextPage <= pageCount;
  const hasPrevPage = prevPage >= 1;

  const goToPage = (number: number) => {
    console.log('asdasdad', number, currentPage);
    if (number === currentPage) {
      return;
    }
    setCurrentPage(number);
    onPageChange(number, pageSize);
  };

  const buttons = [...Array(pageCount)].map((_, i) => (
    <button key={i + 1} onClick={() => goToPage(i + 1)}>
      {i + 1}
    </button>
  ));

  return (
    <PaginationContainer>
      <button className='left' disabled={!hasPrevPage} onClick={() => goToPage(currentPage - 1)}>
        ←
      </button>
      {buttons}
      <button className='right' disabled={!hasNextPage} onClick={() => goToPage(currentPage + 1)}>
        →
      </button>
    </PaginationContainer>
  );
};

export default Pagination;
