import { FormOrder, UpdateFormOrder } from './../../interfaces/order';
import { arrayToIdMapAndOrdering } from './../../utils/utils';
import { ThunkApiParams } from './../store';
import { Order } from '../../interfaces/order';
import { orderReducer } from './order.reducer';
import * as orderService from './../../services/order';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PaginatedResponse, PaginationOptions } from '../../interfaces/utils';
import { orderInitialState } from './order.initialState';

export const fetchOrders = createAsyncThunk<
  PaginatedResponse<{ orders: Order[] }>,
  PaginationOptions,
  ThunkApiParams
>('order/fetchOrders', async (params: PaginationOptions, thunkAPI) => {
  const response = await orderService.fetchOrders(params, thunkAPI.getState().user.token || '');
  return response;
});

export const createOrder = createAsyncThunk<Order, FormOrder, ThunkApiParams>(
  'order/createOrder',
  async (order: FormOrder, thunkAPI) => {
    const response = await orderService.createOrder(order, thunkAPI.getState().user.token || '');
    return response;
  }
);

export const updateOrder = createAsyncThunk<Order, Order | UpdateFormOrder, ThunkApiParams>(
  'user/updateOrder',
  async (order, thunkAPI) => {
    const response = await orderService.updateOrder(order, thunkAPI.getState().user.token || '');
    return response;
  }
);

export const deleteOrder = createAsyncThunk<Order, string, ThunkApiParams>(
  'user/deleteOrder',
  async (orderId, thunkAPI) => {
    const response = await orderService.deleteOrder(orderId, thunkAPI.getState().user.token || '');
    return response;
  }
);

export const orderSlice = createSlice({
  name: 'order',
  initialState: orderInitialState,
  reducers: orderReducer,
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrders.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchOrders.fulfilled, (state, action) => {
        // Add user to the state array

        state.isLoading = false;
        const { orders, total } = action.payload;
        const { idMap } = arrayToIdMapAndOrdering(orders, '_id');
        state.ordersById = idMap;
        state.ordersTotal = total;
      })
      .addCase(createOrder.fulfilled, (state, action) => {
        const order = action.payload as Order;
        state.ordersById[order._id] = order;
      })
      .addCase(updateOrder.fulfilled, (state, action) => {
        const order = action.payload;
        state.ordersById[order._id] = order;
      })
      .addCase(deleteOrder.fulfilled, (state, action) => {
        const order = action.payload;
        delete state.ordersById[order._id];
      });
  },
});

export default orderSlice.reducer;

// export const {
//   actions: { create: createOrderActionCreator },
// } = orderSlice;
