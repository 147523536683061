import styled from '@emotion/styled';

export const LoginButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  border: 0 solid #e2e8f0;
  border-radius: 0.25rem;
  cursor: pointer;

  font-weight: 700;
  padding: 0.75rem 1rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  color: #f7fafc;
  font-size: 0.875rem;
  width: 16rem;
  background: #4285f4;
  margin-top: 10px;
  text-decoration: none;
`;

export const SeparationBar = styled.span`
  border: 0 solid #4299e1;
  height: 1.5rem;
  width: 0.25rem;
  font-weight: 700;
  color: #f7fafc;
  font-size: 0.875rem;
  border-left-width: 1px;
  padding-left: 0.75rem;
`;

export const TextName = styled.span`
  cursor: pointer;
  font-weight: 700;
  color: #f7fafc;
  font-size: 0.875rem;
  text-align: center;
  flex-grow: 1;
`;
