const dimensions = {
  // pixels
  maxwidthDesktop: 1600,
  maxwidthTablet: 1000,
  maxwidthMobile: 600,

  // ems
  paddingHorizontalDesktop: 2,
  paddingHorizontalTablet: 1.5,
  paddingHorizontalMobile: 1,
};

export default dimensions;
