import React, { useEffect, useMemo } from 'react';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { useFormFields } from '../../../hooks/useFormFields';
import { FormOrder, Status } from '../../../interfaces/order';
import { currenciesSelector } from '../../../selectors/currency';
import { proxiesSelector } from '../../../selectors/proxy';
import { shopsSelector } from '../../../selectors/shop';
import { RootState } from '../../../store/store';
import { Form, TextField, SubmitButton, Field, TextLabel } from './OrderForm.styles';
import { arrayToSelectOptions, getSelectOption } from '../../../utils/utils';
import { useSelectCallback } from '../../../hooks/useSelectCallback';
import { Props } from './interfaces';

const OrderForm: React.FC<Props> = ({ onSubmit, defaultValues }) => {
  const isLoading = useSelector((state: RootState) => state.shop.isLoading);
  const shops = useSelector(shopsSelector);
  const proxies = useSelector(proxiesSelector);
  const currencies = useSelector(currenciesSelector);

  const { formFields, setFormField, createChangeHandler } = useFormFields<FormOrder>({
    itemName: '',
    shop: '',
    price: 0,
    currency: '',
    quantity: 0,
    status: Status.wishlist,
    releaseDate: new Date(),
    itemUrl: '',
    ...defaultValues,
  });

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    onSubmit(formFields);
  };

  useEffect(() => {
    if (shops.length) {
      setFormField('shop', defaultValues?.shop || shops[0]._id);
    }
  }, [shops]);

  useEffect(() => {
    if (currencies.length) {
      setFormField('currency', defaultValues?.currency || currencies[0]._id);
    }
  }, [currencies]);

  const shopOptions = useMemo(() => arrayToSelectOptions(shops, 'name'), [shops]);
  const proxyOptions = useMemo(() => arrayToSelectOptions(proxies, 'name'), [proxies]);
  const currencyOptions = useMemo(() => arrayToSelectOptions(currencies, 'shortName'), [
    currencies,
  ]);
  const statusOptions = Object.keys(Status).map((status) => ({
    value: status,
    label: Status[status as keyof typeof Status] as string,
  }));

  const shopValue = getSelectOption(formFields.shop, shops, 'name');
  const proxyValue = formFields.proxy
    ? getSelectOption(formFields.proxy, proxies, 'name')
    : undefined;
  const currencyValue = getSelectOption(formFields.currency, currencies, 'shortName');
  const statusValue = {
    value: formFields.status as string,
    label: Status[formFields.status] as string,
  };

  const onShopChange = useSelectCallback(setFormField, 'shop');
  const onProxyChange = useSelectCallback(setFormField, 'proxy');
  const onCurrencyChange = useSelectCallback(setFormField, 'currency');
  const onStatusChange = useSelectCallback(setFormField, 'status');

  return (
    <Form onSubmit={handleSubmit}>
      <Field fieldName='name'>
        <TextLabel htmlFor='itemName'>Name</TextLabel>
        <TextField
          type='text'
          id='itemName'
          value={formFields.itemName}
          onChange={createChangeHandler('itemName')}
        />
      </Field>
      <Field fieldName='shop'>
        <Select id='shop' options={shopOptions} value={shopValue} onChange={onShopChange} />
        <TextLabel htmlFor='shop'>Shop</TextLabel>
      </Field>
      <Field fieldName='proxy'>
        <Select
          id='proxy'
          options={proxyOptions}
          value={proxyValue}
          onChange={onProxyChange}
          isClearable
        />
        <TextLabel htmlFor='proxy'>Proxy</TextLabel>
      </Field>
      <Field fieldName='itemUrl'>
        <TextLabel htmlFor='itemUrl'>URL</TextLabel>
        <TextField
          type='text'
          id='itemUrl'
          value={formFields.itemUrl}
          onChange={createChangeHandler('itemUrl')}
          required
        />
      </Field>
      <Field fieldName='price'>
        <TextLabel htmlFor='price'>Price</TextLabel>
        <TextField
          type='number'
          min='0'
          id='price'
          value={formFields.price}
          onChange={createChangeHandler('price')}
          required
        />
      </Field>
      <Field fieldName='quantity'>
        <TextField
          type='number'
          min='0'
          id='quantity'
          value={formFields.quantity}
          onChange={createChangeHandler('quantity')}
          required
        />
        <TextLabel htmlFor='quantity'>Quantity</TextLabel>
      </Field>
      <Field fieldName='currency'>
        <Select
          id='currency'
          options={currencyOptions}
          value={currencyValue}
          onChange={onCurrencyChange}
        />
        <TextLabel htmlFor='currency'>Currency</TextLabel>
      </Field>
      <Field fieldName='status'>
        <Select id='status' options={statusOptions} value={statusValue} onChange={onStatusChange} />
        <TextLabel htmlFor='status'>Status</TextLabel>
      </Field>
      <Field fieldName='submit'>
        <SubmitButton type='submit' disabled={isLoading}>
          Submit
        </SubmitButton>
      </Field>
    </Form>
  );
};

export default OrderForm;
