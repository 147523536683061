import userReducer from './user/user.slice';
import orderReducer from './order/order.slice';
import shopReducer from './shop/shop.slice';
import proxyReducer from './proxy/proxy.slice';
import currencyReducer from './currency/currency.slice';

export const reducer = {
  user: userReducer,
  order: orderReducer,
  shop: shopReducer,
  proxy: proxyReducer,
  currency: currencyReducer,
};
