import React from 'react';
import { useDispatch } from 'react-redux';
import { FormOrder } from '../../../interfaces/order';
import { updateOrder } from '../../../store/order/order.slice';
import OrderForm from '../OrderForm';
import { Props } from './interfaces';
import { ModalContainer } from './OrderEdit.styles';

const OrderEdit: React.FC<Props> = ({ close, order }) => {
  const dispatch = useDispatch();

  const processedOrder: FormOrder = {
    ...order,
    shop: order.shop?._id,
    proxy: order.proxy?._id,
    currency: order.currency._id,
  };
  return (
    <ModalContainer>
      <OrderForm
        onSubmit={(formOrder) => {
          dispatch(updateOrder({ ...formOrder, proxy: formOrder.proxy || null, _id: order._id }));
          close();
        }}
        defaultValues={processedOrder}
      />
    </ModalContainer>
  );
};

export default OrderEdit;
