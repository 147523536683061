import { createSelector } from 'reselect';
import { RootState } from '../store/store';
import { createSimpleArraySelector } from '../utils/selectors';

const currenciesByIdSelector = (state: RootState) => state.currency.currenciesById;
const userSelector = (state: RootState) => state.user.user;

export const currenciesSelector = createSimpleArraySelector(currenciesByIdSelector, {
  sortField: 'shortName',
  converToNumber: false,
});

export const userBaseCurrencySelector = createSelector(
  [currenciesByIdSelector, userSelector],
  (currenciesById, user) => {
    const baseCurrency = user?.settings?.baseCurrency;
    if (!baseCurrency) {
      return undefined;
    }
    return currenciesById[baseCurrency];
  }
);
