import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
import dimensions from '../../utils/dimensions';

export const HeaderWraper = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  max-width: 1600px;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;

  border: 1px solid red;

  max-width: ${dimensions.maxwidthDesktop}px;
  padding-left: ${dimensions.paddingHorizontalDesktop}em;
  padding-right: ${dimensions.paddingHorizontalDesktop}em;

  @media (max-width: ${dimensions.maxwidthTablet}px) {
    padding-left: ${dimensions.paddingHorizontalTablet}em;
    padding-right: ${dimensions.paddingHorizontalTablet}em;
  }
  @media (max-width: ${dimensions.maxwidthMobile}px) {
    padding-left: ${dimensions.paddingHorizontalMobile}em;
    padding-right: ${dimensions.paddingHorizontalMobile}em;
  }
`;

export const HeaderTitle = styled.h1`
  margin: 0;
  font-size: 1.25em;
  padding: 1.5rem 1rem;
`;

export const HeaderMenu = styled.ul`
  display: flex;
  justify-content: space-around;
  text-decoration: none;
  list-style: none;
  margin: 0;
  padding: 0;

  @media (max-width: ${dimensions.maxwidthMobile}px) {
    display: none;
  }
`;

export const HeaderChildren = styled.nav`
  li {
    display: flex;
    justify-content: space-around;
    text-decoration: none;
    list-style: none;
    margin: 0;
    padding: 1.5rem 1rem;
  }
`;

export const HeaderChildrenLogOut = styled(HeaderChildren)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${dimensions.maxwidthMobile}px) {
    display: none;
  }
`;

export const HeaderChildrenMenu = styled(HeaderChildren)`
  @media (max-width: ${dimensions.maxwidthMobile}px) {
    display: none;
  }
`;

export const HeaderLink = styled(NavLink)`
  text-decoration: none;
  color: inherit;
`;

export const AvatarNavLink = styled(NavLink)`
  margin-right: 1em;
`;

export const LogOut = styled.button`
  padding: 0.5em 1.75em;
  font-weight: 700;
  text-decoration: none;
  border: 0;
  cursor: pointer;
`;

export const Avatar = styled.img`
  border-radius: 50%;
  max-height: 3em;
  max-width: 100%;
  object-fit: cover;
  object-position: 50% 50%;
`;
