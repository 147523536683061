import * as React from 'react';
import ReactDOM from 'react-dom';
import { Wrapper } from './Modal.styles';

import { Props } from './interfaces';

const modalRoot = document.getElementById('modal-root') as HTMLFormElement;

const PortalModal: React.FC<Props> = ({
  onClickedOutside,
  dimBackground = true,
  visible,
  verticallyCentered = true,
  children,
}) => {
  const modalWrapperId = 'modal-wrapper';
  const modalContainer = (
    <Wrapper
      id={modalWrapperId}
      dimBackground={dimBackground}
      verticallyCentered={verticallyCentered}
      ignoreClicks={!onClickedOutside}
      onClick={(e) => {
        if ((e.target as Element).id !== modalWrapperId) {
          return;
        }
        onClickedOutside && onClickedOutside();
      }}
    >
      {children}
    </Wrapper>
  );

  return ReactDOM.createPortal(visible ? modalContainer : null, modalRoot);
};

export default PortalModal;
