import { createSelector, OutputSelector } from 'reselect';
import { IdMap } from '../interfaces/utils';
import { RootState } from '../store/store';

interface SortOptions<T> {
  sortField?: keyof T;
  converToNumber?: boolean;
  reverse?: boolean;
  comparator?: (a: T, b: T) => number;
}

export const createSimpleArraySelector = <T extends { _id: string }>(
  mapSelector: (state: RootState) => IdMap<T>,
  sortOptions?: SortOptions<T>
): OutputSelector<RootState, T[], (res: IdMap<T>) => T[]> => {
  const sortField = sortOptions?.sortField ?? '_id';
  const converToNumber = sortOptions?.converToNumber ?? true;
  const reverse = sortOptions?.reverse ?? false;
  const comparator =
    sortOptions?.comparator ??
    ((a, b) => {
      if (converToNumber) {
        return (reverse ? -1 : 1) * (Number(a[sortField]) - Number(b[sortField]));
      }
      if (a[sortField] === b[sortField]) {
        return 0;
      }
      return (reverse ? -1 : 1) * (a[sortField] > b[sortField] ? 1 : -1);
    });

  return createSelector(mapSelector, (itemsById) =>
    Object.keys(itemsById)
      .map((id) => itemsById[id])
      .sort(comparator)
  );
};
