import { Currency } from '../../interfaces/currency';
import { IdMap } from '../../interfaces/utils';

export interface CurrencyState {
  currenciesById: IdMap<Currency>;
  isLoading: boolean;
}

export const currencyInitialState: CurrencyState = {
  currenciesById: {},
  isLoading: false,
};
