import * as jwt from 'jsonwebtoken';
import { User } from '../../interfaces/user';
import { Optional } from '../../interfaces/utils';

const parseToken = (token: string): Optional<User> => {
  try {
    return jwt.decode(token) as User;
  } catch (error) {
    console.error(error);
  }
};

export default parseToken;
