import { Order } from '../../interfaces/order';
import { IdMap } from '../../interfaces/utils';

export interface OrderState {
  ordersById: IdMap<Order>;
  ordersTotal: number;
  isLoading: boolean;
}

export const orderInitialState: OrderState = {
  ordersById: {},
  ordersTotal: 0,
  isLoading: false,
};
