import styled from '@emotion/styled';

type WrapperProps = {
  dimBackground?: boolean;
  verticallyCentered?: boolean;
  ignoreClicks?: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100%;
  overflow-y: auto;
  background: ${({ dimBackground }) => (dimBackground ? 'rgba(0, 0, 0, 0.4)' : 'transparent')};
  pointer-events: ${({ ignoreClicks }) => (ignoreClicks ? 'none' : 'auto')};
`;
