import React from 'react';
import {
  HeaderWraper,
  HeaderChildren,
  HeaderChildrenMenu,
  HeaderChildrenLogOut,
  HeaderLink,
  HeaderTitle,
  HeaderMenu,
  AvatarNavLink,
  LogOut,
  Avatar,
} from './Header.styles';
import { Props } from './Header.interfaces';
import { useSelector } from 'react-redux';
import { isUserLoggedInSelector } from '../../selectors/user';
import MobileHeader from './MobileHeader';
import { RootState } from '../../store/store';

const Header: React.FC<Props> = ({ logout }) => {
  const isUserLoggedIn = useSelector(isUserLoggedInSelector);

  const user = useSelector((state: RootState) => state.user.user);

  const logOut = () => {
    logout();
  };

  const homeRoute = !isUserLoggedIn ? '/' : '/home';

  const menuOptions: JSX.Element = (
    <>
      <li>
        <HeaderLink activeClassName='current-page' to='/home'>
          Home
        </HeaderLink>
      </li>
      <li>
        <HeaderLink activeClassName='current-page' to='/orders'>
          Orders
        </HeaderLink>
      </li>
      <li>
        <HeaderLink activeClassName='current-page' to='/about'>
          About
        </HeaderLink>
      </li>
    </>
  );

  return (
    <HeaderWraper>
      <HeaderChildren>
        <HeaderLink to={homeRoute}>
          <HeaderTitle id='logo'>Fantastic Sloth</HeaderTitle>
        </HeaderLink>
      </HeaderChildren>
      <MobileHeader />
      <HeaderChildrenMenu>
        <HeaderMenu>{!isUserLoggedIn ? null : menuOptions}</HeaderMenu>
      </HeaderChildrenMenu>
      <HeaderChildrenLogOut>
        {!isUserLoggedIn ? null : (
          <AvatarNavLink to='/settings'>
            <Avatar src={`data:image/jpeg;base64,${user?.avatarBase64}`} />
          </AvatarNavLink>
        )}
        {!isUserLoggedIn ? null : <LogOut onClick={logOut}>Log Out</LogOut>}
      </HeaderChildrenLogOut>
    </HeaderWraper>
  );
};

export default Header;
